@import url('https://fonts.googleapis.com/css2?family=Alata&family=Staatliches&display=swap');

body {
  background-color: rgb(247, 246, 241);
  font-family: 'Alata', sans-serif;
}

header {
  display: flex;
  border-bottom: solid black 3px;
  height: 4em;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

header h1 {
  height: 3.5em;
  margin: 10px;
  text-align: center;
  font-family: 'Staatliches', cursive;
  font-size: 3.0em;
}

main {
  align-items: center;
}

main h1 {
  text-align: center;
}

nav {
  justify-content: space-evenly;
  font-family: 'Alata', sans-serif;
  font-size: 1.5em;
  text-align: center;
  align-self: flex-end;
  margin-left: auto;
}

a {
  text-decoration: none;
  color: black;
}

nav ul li a {
  padding-top: 1.5em;
}

img {
  display: block;
  margin: auto;
  margin-top: 15px;
}

div {
  margin: auto;
}

nav ul {
  display: flex;
  margin: 0;
  padding: 0;
}

header div ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.75em;
}

nav li {
  list-style: none;
  margin-left: 25px;
}

footer {
  margin-top: 250px;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.footerCard {
  width: 200px;
  height: 1.5em;
  font-size: 1em;
  color: white;
  text-align: center;
}

.bauhaus-button {
  margin-top: 10px;
  background-color: rgb(36, 89, 165);
  color: black;
  border: 3px solid;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  padding: 15px 32px;
  font-size: 1.2em;
  cursor: pointer;
  font-family: 'Alata', sans-serif;
}

.carousel-div {
  width: 90%;
  max-width: 600px;
}

#artistStatement {
  text-align: center;
}

.artImage {
  margin-top: 0;
  display: none;
  height: 400px;
}

#carouselImage img:first-of-type {
  display: block;
}

.dropdown {
  display: none;
  z-index: 2;
}

#links {
  position: static;
}

#bioDiv {
  width: 90%;
  max-width: 500px;
}

#resumeDiv {
  width: 90%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#flexDiv {
  display: flex;
  width: 60%;
  flex-direction: column;
}

#stickyFooter {
  position: sticky;
  bottom: 0;
}

.navCardContainer {
  display: flex;
  justify-content: center;
}

.navCard {
  width: 300px;
  height: 300px;
  margin: 10px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 1.5em;
  color: white;
  border-radius: 5px;
}

.navCard:hover {
  z-index: 5 !important;
  writing-mode: horizontal-tb;
}

.navCard:hover .linkText {
  display: block;
}


.navA {
  width: 290px;
  height: 290px;
}

div a {
  margin: 5px;
}

#first {
  z-index: 4;
  background-color: rgb(255, 90, 75);
}

#second {
  z-index: 3;
  margin-left: -200px;
  background-color: rgb(255, 117, 75);
}

#third {
  z-index: 2;
  margin-left: -200px;
  background-color: rgb(255, 146, 80);
}

#fourth {
  z-index: 1;
  margin-left: -200px;
  background-color: rgb(255, 185, 90);
}

.linkText {
  display: none;
  margin: 5px;
}


.linkDiv {
  /* mostly from w3 schools code snips - making a div a link */
  font-size: 2em;
  color: white;
  width: 100%;
  max-width: 380px;
  height: 2em;
  line-height: 2;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  margin: 10px;
  ;
}

li a {
  cursor: pointer;
}

header h1 {
  cursor: pointer;
}


.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

#git {
  background-color: rgb(32, 37, 41);
}

#linkedIn {
  background-color: rgb(0, 93, 181);
}

tr,
th,
td {
  border: solid black 1px;
}

.textDiv {
  width: 90%;
  max-width: 500px;
  text-align: center;
}

#bioPic {
  width: 350px;
}

#mobileNav {
  display: none;
}

#mobileNavLinks {
  width: 100%;
}

@media (max-width: 800px) {
  header {
    flex-direction: column;
    height: auto;
  }

  header h1 {
    height: auto;
    font-size: 2.5em;
  }

  header nav {
    display: none;
  }

  #mobileNav {
    display: block
  }

  .navCardContainer {
    flex-wrap: wrap;
  }

  .navCard {
    margin: 20px;
    width: 70%;
    height: auto;
    border-right: 100px;
    z-index: 0;
    writing-mode: horizontal-tb;
  }

  .linkText {
    display: block;
    margin: 5px;
  }

  #first {
    z-index: 0;
  }

  #second {
    z-index: 0;
    margin: 20px;
  }

  #third {
    z-index: 0;
    margin: 20px;
  }

  #fourth {
    z-index: 0;
    margin: 20px;
  }

  #artCarouselContainer {
    display: flex;
    height: 300px;
    width: 90%;
  }

  footer {
    margin-top: 6em;
    justify-content: center;
  }

  .footerCard {
    width: 150px;
  }

  #artFooter {
    margin-top: 10em;
  }

  form {
    display: flex;
    flex-direction: column;
  }
}